import React from "react";
import { Link } from "react-router-dom";
import firework from '../assets/img/firework.JPG'


// Define the last updated date
const lastUpdated = "December 11, 2024"; // Update this when necessary

function Now() {
    return (
        <>
            <section id="now" className="*:selection:bg-primary *:selection:text-white flex flex-col justify-center px-5 min-h-screen text-white">
                <div id="now-page" className="flex justify-center mt-20">
                    <div className="grid grid-cols-1 sm:w-2/3 text-start">
                        <div className="col-span-4 flex flex-col items-start">
                        <div className="">
                                <p className='pt-3 font-raleway text-4xl font-bold uppercase text-justify text-white'>
                                    <Link to="/" className="">&larr;</Link>
                                </p>
                            </div>
                            <p className="font-raleway text-sm font-bold mb-2"> 
                                <span className="uppercase">What I'm Doing Now </span>
                            </p>

                            {/* current activities */}
                            <div className="mb-12">
                                {/* <p className="font-raleway text-md font-bold uppercase">Current Focus</p> */}
                                <ul className="list-none">
                                    <li>
                                        <p className="font-raleway text-md font-normal text-justify mt-2">
                                            Been trying to read more lately, right now following a roadmap to understand LLMs: (1) <i><a href="https://writings.stephenwolfram.com/2023/02/what-is-chatgpt-doing-and-why-does-it-work/" target="_blank" className="link" rel="noreferrer">What Is ChatGPT Doing and Why Does It Work?</a></i> (2) Understanding the UAT (3) Building a simple feedforward network (in Python) (4) <i><a href="https://arxiv.org/pdf/1706.03762" className="link" target="_blank" rel="noreferrer">Attention is All You Need</a></i>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="font-raleway text-md font-normal text-justify mt-2">
                                            Besides this, I'm reading <i>Ways of Seeing</i> by John Berger and taking some photos
                                            <img src={firework} alt="Firewoooork" className="w-64" />
                                        </p>
                                    </li>
                                    <li className="pt-4">
                                        <p className="font-raleway text-md font-normal text-justify mt-2">
                                            <i>
                                            <a href="https://open.spotify.com/track/0DyKO8gNxis0AkyhOjuXxi?si=f0882b34dbb54bca" className="link" target="_blank" rel="noreferrer">
                                                Gaivota - Amália Rodrigues
                                            </a>
                                            </i>
                                        </p>
                                    </li>
                                </ul>
                                
                            </div>

                            {/* last updated */}
                            <div className="mb-8">
                                <p className="font-raleway text-sm font-normal  text-justify">
                                    Last update was <span className="font-normal">{lastUpdated}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="now-insp" className="px-5 flex flex-col justify-center items-center text-center">
                <p className="font-raleway text-xs font-bold uppercase text-justify text-white">
                    the creation of this <span className="bg-blue-600">/now</span > page was inspired by Derek Sivers' - 
                    <a href="https://sive.rs/nowff" rel="noreferrer" target="_blank" className="text-black bg-white ml-1">sive.rs/nowff</a>
                </p>
            </section>
        </>
    );
}

export default Now;